.rdrCalendarWrapper {
  width: 100%;
}

.rdrMonth {
  width: 100%;
}

.overlayPerson {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.data-overlay {
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 30%;
  left: 70%;
  width: 300px;
  font-size: 0.9rem;
  color: #000;
  transform: translate(-50%, -50%);
  background-color: #fff;
}

.staffItem {
  display: flex;
  align-items: center;
  padding: 5px 5px;
}

.staffItem img {
  border-radius: 10px;
}

.staffItem:hover {
  background-color: #ccc;
}

@media only screen and (max-width: 540px) {
  .data-overlay {
    position: absolute;
    justify-content: center;
    align-items: center;
    top: 60%;
    left: 70%;
    width: 200px;
    font-size: 0.9rem;
    color: #000;
    transform: translate(-50%, -50%);
    background-color: #fff;
  }
}
