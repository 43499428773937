.overlaySend {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.loadingContainer {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}
