* {
  box-sizing: border-box;
}

i {
  color: #0d6efd;
}

.feedBack {
  width: 100%;
  text-align: center;
  margin: 10% auto;
  word-wrap: break-word;
}
