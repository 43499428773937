.container-main {
  width: 100%;
  margin: 0 auto;
  background-color: #fff;
  /* border-radius: 1%; */
  margin-top: 50px;
  border-top: 1px solid #cdcdcd;
  color: #333;
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem;
}
.vl {
  border-left: 1px solid #cdcdcd;
  height: auto;
}

.left-side {
  background-color: #fff;
  width: 40%;
  height: 100%;
  margin: 20px;
}

.right-side {
  /* background-color: red; */
  width: 60%;
  height: 100%;
  margin: 20px;
}

.service-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  height: 100px;
  margin: 5px 0;
  /* padding: 0 20px; */
  padding-right: 20px;
  transition: box-shadow 0.3s;
}

.hide-check {
  display: none;
}

.service-right i {
  font-size: 1.5rem;
}

.service-right:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  border-radius: 2px;
}

.service-name {
  display: flex;
  align-items: center;
  font-size: 0.85rem;
}

.service-duration {
  font-size: 0.8rem;
}

.service {
  padding: 10px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px;
  color: gray;
}

.hover-effect {
  cursor: pointer;
  color: #333;
}

.active {
  background-color: #f6f6f8;
  border-radius: 2px;
}

.hover-effect:hover {
  background-color: #f6f6f8;
  transition: background-color 0.5s;
}

hr {
  margin: 0;
}

@media only screen and (max-width: 991px) {
  /* body {
      background-color: lightblue;
    } */

  .right-side {
    width: 100%;
    height: 100%;
  }

  .service-name {
    display: flex;
    width: 100%;
  }

  .service-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 100px;
    margin: 5px 0;
    padding: 0 20px;
    transition: box-shadow 0.3s;
  }
}

@media only screen and (max-width: 540px) {
  /* body {
      background-color: lightblue;
    } */

  .container-main {
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .left-side {
    flex-direction: row;
    width: 90%;
  }

  .right-side {
    width: 100%;
    height: 100%;
  }

  .service-right {
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    height: 100px;
    margin: 5px 0;
    padding: 0 20px;
    transition: box-shadow 0.3s;
  }
}
